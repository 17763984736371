const CHANNEL_CODES = require('./channelCodes');

// refactor code to use just one code for all channels
const GOOGLE_MAP_KEY_FOR_CHANNEL = {
  [CHANNEL_CODES.GB]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.DE]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.ES]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.FR]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.BE]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.IE]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.IT]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.AT]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.CH]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.NL]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.LT]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.DK]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.LV]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.GR]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
  [CHANNEL_CODES.PT]: 'AIzaSyACo2ol1GMeAOlTekBpbTrT_4gZIbXVwGA',
};

module.exports = GOOGLE_MAP_KEY_FOR_CHANNEL;
