import React, { useEffect } from 'react';
import Dialog from 'components/common/react-dialog';
import { CancellationFlowAnalytics } from 'common/analytics';
import { Button } from 'components/common/Button';
import Wahanda from 'common/wahanda';

import style from './CancelThroughSupport.scss';

interface Props {
  onClose: () => void;
}

export const CancelThroughSupport = (props: Props) => {
  const { onClose } = props;

  useEffect(() => {
    CancellationFlowAnalytics.trackViewRequestCancellation();
  }, []);

  const openSupportForm = () => {
    CancellationFlowAnalytics.trackClickRequestCancellationForm();
    onClose();
  };

  return (
    <Dialog
      width={350}
      title={
        Wahanda.lang.calendar.appointments.cancellation.marketplace.requestCancellationPhone.title
      }
      classes={{ 'dialog2--button-choices appointment-deletion-proxy': true }}
      onClose={onClose}
    >
      <div className={style.header}>
        {Wahanda.lang.calendar.appointments.cancellation.marketplace.requestCancellation.heading}
      </div>
      <div className={style.button}>
        <Button
          label={
            Wahanda.lang.calendar.appointments.cancellation.marketplace.requestCancellation.continue
          }
          href={
            Wahanda.lang.calendar.appointments.cancellation.marketplace.requestCancellation.link
          }
          opensInNewWindow
          onClick={openSupportForm}
          variant="primary"
          fullWidth
          size="large"
          dataTest="open-cancellation-request-form"
        />
      </div>
    </Dialog>
  );
};
