/* global google */
import React from 'react';
import { colors } from 'style/exports/colors';

interface IColumnChartProps extends React.HTMLAttributes<Element> {
  className?: string;
  headers: string[];
  data?: (string | number)[][];
}
type ColumnChartState = {
  canRenderChart?: boolean;
  chart?: any;
};

export default class ColumnChart extends React.Component<IColumnChartProps, ColumnChartState> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'chartRef' has no initializer and is not ... Remove this comment to see the full error message
  chartRef: HTMLDivElement;

  forceUpdate: any;

  static defaultProps = {
    className: null,
    data: null,
  };

  state = {
    canRenderChart: false,
    chart: null,
  };

  componentDidMount() {
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'google'.
    google.load('visualization', '1', {
      packages: ['corechart'],
      callback: () => {
        window.addEventListener('resize', this.renderOnResize);
        this.setState({
          canRenderChart: true,
          // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'google'.
          chart: new google.visualization.ColumnChart(this.chartRef),
        });
      },
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.renderOnResize);
  }

  renderOnResize = () => {
    const { canRenderChart } = this.state;
    if (canRenderChart) {
      this.forceUpdate();
    }
  };

  renderSalesChart() {
    const { chart } = this.state;
    if (!this.props.data) {
      return null;
    }
    if (!chart) {
      return null;
    }
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'google'.
    const data = google.visualization.arrayToDataTable([this.props.headers, ...this.props.data]);
    const options = {
      isStacked: true,
      legend: { position: 'none' },
      colors: [colors.colorGreen],
    };
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    chart.draw(data, options);
  }

  render() {
    const { className } = this.props;
    const { canRenderChart } = this.state;
    return (
      <div
        className={className}
        ref={(chartRef) => {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLDivElem... Remove this comment to see the full error message
          this.chartRef = chartRef;
        }}
      >
        {canRenderChart && this.renderSalesChart()}
      </div>
    );
  }
}
