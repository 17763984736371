import React, { useEffect, useState, useRef } from 'react';
import { fetchRemoteModule } from './fetchModule';
import styles from './Reconnect.module.css';

const remoteUrl = `/reconnect/remoteEntry.js`;

export const useRemoteModule = <T,>(scope: string, module: string) => {
  const [loadedModule, setModule] = useState<T | null>(null);

  useEffect(() => {
    fetchRemoteModule({
      remoteUrl,
      scope,
      module,
    })
      .then((fetchedModule) => {
        setModule(fetchedModule);
      })
      .catch(() => {});
  }, [module, scope]);

  return loadedModule;
};

const useAppConfig = () => {
  const config = {
    countryCode: App.config.get('venue').countryCode,
    supplierId: App.getSupplierId(),
    isVatFeatureEnabled: App.config.isVatPayerFeatureEnabled(),
  };
  return config;
};

export type Inject = (
  node: HTMLDivElement,
  name: string,
  config: Config,
  props?: Record<string, any>,
) => () => void;

export const Reconnect = ({
  name,
  props,
}: {
  // TODO: This should use the `ValidComponent` type from reconnect but
  // currently that causes TypeScript to see all of the reconnect files and try
  // to typecheck them based on React 16 types, which causes issues.
  // This can be changed once we update to React 18 in connect desktop
  // https://wahanda.atlassian.net/browse/CD-1242
  name: string;
  props?: Record<string, unknown>;
}) => {
  const remoteModule = useRemoteModule<{ inject: Inject }>('reconnect', './remoteEntry');
  const config = useAppConfig();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!remoteModule || !ref.current) {
      return;
    }
    const unmount = remoteModule.inject(ref.current, name, config, props);
    return () => unmount();
  }, [remoteModule, name, props]);
  return <div ref={ref} className={styles.reconnect} />;
};
