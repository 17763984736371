import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'components/common/Modal';
import { Button } from 'components/common/Button';
import { AutomatedMessagingCategory } from 'components/marketing/AutomatedMessaging/types';
import { usePhoneNumber } from 'components/calendar/InvalidCustomSenderIdModal/usePhoneNumber';
import Markdown from 'markdown-to-jsx';
import styles from './index.scss';
import { isDismissed, dismiss } from './storage';
import { redirectTo } from './redirectTo';
import { getTrackingByEventName } from 'src/tracking-container/TrackingEvents';

const trackEvent = getTrackingByEventName('invalid_sms_sender_modal');

export const InvalidCustomSenderIdModal = () => {
  const [isOpen, setOpen] = useState(false);
  const { isValid } = usePhoneNumber({ enabled: !isDismissed() });

  useEffect(() => {
    !isValid && open();
  }, [isValid]);

  const open = () => {
    setOpen(true);
    trackEvent('open');
  };

  const close = () => {
    setOpen(false);
    trackEvent('close');
  };

  const onSkip = () => {
    dismiss({ forever: true });
    close();
    trackEvent('skip');
  };

  const onClose = () => {
    dismiss();
    close();
  };

  const onUpdate = () => {
    trackEvent('update');
    redirectTo({
      paths: ['marketing', 'automated-messaging'],
      tab: AutomatedMessagingCategory.TRANSACTIONAL,
      isAdvancedSettingsVisible: true,
    });
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <Modal
      title={Wahanda.lang.calendar.invalidCustomSenderId.modal.title}
      type="medium"
      onClose={onClose}
    >
      <ModalBody>
        <Markdown className={styles.body}>
          {Wahanda.lang.calendar.invalidCustomSenderId.modal.body}
        </Markdown>
      </ModalBody>
      <ModalFooter>
        <Button
          label={Wahanda.lang.calendar.invalidCustomSenderId.modal.button.skip}
          variant="secondary"
          colour="plain"
          onClick={onSkip}
        />
        <Button
          dataTest="identity-verification-modal-submit"
          label={Wahanda.lang.calendar.invalidCustomSenderId.modal.button.update}
          onClick={onUpdate}
        />
      </ModalFooter>
    </Modal>
  );
};
