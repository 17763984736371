/**
 * This is a workaround to use navigation that is managed by Backbone.
 */
export const navigateTo = (path: string) => {
  const isInSettings = window.location.href.includes('settings');
  const goToSettings = path.includes('settings');
  if (isInSettings && goToSettings) {
    return navigateInsideSettings(path);
  }
  window.location.assign(path);
};

const navigateInsideSettings = (path: string) => {
  switch (true) {
    case path.includes('bank-details'):
      getAnchorElement('#finance-tab')?.click();
      getAnchorElement('#finance-subtab-bank-details')?.click();
      break;
    case path.includes('venue-details'):
      getAnchorElement('#venue-settings-tab')?.click();
      getAnchorElement('#venue-settings-subtabs-details')?.click();
      break;
    case path.includes('billing-details'):
      getAnchorElement('#finance-tab')?.click();
      getAnchorElement('#finance-subtab-billing-details')?.click();
      break;
  }
};

const getAnchorElement = (selector: string) =>
  document.querySelector(`${selector} a`) as HTMLAnchorElement | null;
