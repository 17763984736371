const CHANNEL_CODES = {
  GB: 'WHN_GB',
  DE: 'WHN_DE',
  ES: 'WHN_ES',
  GR: 'WHN_GR',
  PT: 'WHN_PT',
  FR: 'WHN_FR',
  BE: 'WHN_BE',
  IE: 'WHN_IE',
  IT: 'WHN_IT',
  AT: 'WHN_AT',
  CH: 'WHN_CH',
  NL: 'WHN_NL',
  LT: 'WHN_LT',
  DK: 'WHN_DK',
  LV: 'WHN_LV',
};

module.exports = CHANNEL_CODES;
