(function () {
  /**
   * Currency operations and formatting.
   */
  Wahanda.Currency = {
    /**
     * Format currency for output to user.
     *
     * @param float c Money amount
     * @param Object options Options for formatting. @see Wahanda.Number.formatNumber
     * @return string Formatted currency string
     */
    format: function (c, options) {
      let amount = Wahanda.Number.formatNumberDefault(c, options);

      let negativeSign = '';
      if (amount < 0) {
        negativeSign = '-';
        amount *= -1;
      }

      return negativeSign + Wahanda.Currency.wrapNumber(amount);
    },

    /**
     * Wrap the given number with currenct.
     *
     * @param Number amount
     *
     * @return string
     */
    wrapNumber: function (amount) {
      const curr = App.config.get('currency');
      const currencyFormat = curr.currencyFormatCode;

      switch (currencyFormat) {
        case 'WHITESPACE_CODE':
          return `${amount} ${curr.currencyCode}`;
        case 'CODE_WHITESPACE':
          return `${curr.currencyCode} ${amount}`;
        case 'WHITESPACE_SYMBOL':
          return `${amount} ${curr.symbol}`;
        case 'SYMBOL_WHITESPACE':
          return `${curr.symbol} ${amount}`;
        case 'CODE_END':
          return amount + curr.currencyCode;
        case 'CODE_START':
          return curr.currencyCode + amount;
        case 'SYMBOL_END':
          return amount + curr.symbol;
        case 'SYMBOL_START':
          return curr.symbol + amount;
        default:
          return '';
      }
    },

    /**
     * If the given value is a number, return it formatted. Otherwise return an empty string.
     *
     * @param float value (optional)
     * @returns String
     */
    getFormattedOrEmpty: function (value) {
      const amount = Wahanda.Number.formatForDisplay(value);
      if (amount == null) {
        return '';
      }
      return Wahanda.Currency.wrapNumber(amount);
    },

    formatFinancial: function (value) {
      const amount = value || 0;
      return Wahanda.Currency.wrapNumber(Wahanda.Number.formatFinancial(amount));
    },

    /**
     * Return the given value formatter as a number (even if it's not a number).
     *
     * @param float amount (optional)
     * @returns String
     */
    getFormatted: function (value) {
      let amount = parseFloat(value);

      if (Number.isNaN(amount)) {
        amount = 0;
      }

      return Wahanda.Currency.getFormattedOrEmpty(amount);
    },

    /**
     * Returns discount %.
     *
     * @param float currentAmount
     * @param float realAmount
     * @return int
     */
    getDiscount: function (currentAmount, realAmount) {
      return Math.round(((realAmount - currentAmount) / realAmount) * 100);
    },

    /**
     * Return the symbol if it exists. Currencies with a Code will have their symbol set to null.
     * @return string
     */
    getCurrencySymbolOrCode: function () {
      const curr = App.config.get('currency');
      return curr.symbol || curr.currencyCode;
    },

    isCurrencyCode: function () {
      const curr = App.config.get('currency');
      return Boolean(curr.currencyCode);
    },
  };
})();
