import { all, call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import {
  setReminderSettingsRequest,
  setReminderSettingsSuccess,
  setAutomatedMessagingSettingRequest,
  setAutomatedMessagingSettingSuccess,
  automatedMessagingSettingsSuccess,
  automatedMessagingSettingsFailure,
} from './actions';
import {
  BirthdayCongratulationsSetting,
  SendPatchTestInvitesSetting,
  SETTING,
  AutomatedMessagingSettings,
  ReminderSettings,
  SendAppointmentFollowupSetting,
} from './types';

export function* automatedMessagingSettingsRequested() {
  try {
    // a new microservice has been introduced for marketing campaigns
    // while existing functionality has not been moved there, we have to use both APIs
    const { deprecatedApiResponse, apiResponse } = yield all({
      deprecatedApiResponse: call(api.get, apiUrl('VENUE')),
      apiResponse:
        Wahanda.Features.isEnabled('automated-messaging-birthday-campaign') &&
        call(api.get, apiUrl('SUPPLIER_MARKETING_CAMPAIGNS_VENUE_SETTINGS')),
    });

    const settings: AutomatedMessagingSettings | ReminderSettings = {
      [SETTING.GENERAL]: deprecatedApiResponse.sendEmailNotifications,
      [SETTING.SEND_HELPFUL_REMINDERS]:
        deprecatedApiResponse.sendEmailReminders || deprecatedApiResponse.sendSmsReminders,
      [SETTING.SEND_APPOINTMENT_FOLLOWUP]: deprecatedApiResponse.sendAppointmentFollowup,
      [SETTING.ASK_FOR_REVIEWS]: deprecatedApiResponse.directReviewRequestEnabled,
      [SETTING.INVITES_TO_REBOOK]: deprecatedApiResponse.sendInvitesToRebook,
      [SETTING.CONSENT_FOR_APPVOCACY]: deprecatedApiResponse.consentedForAppAdvocacy,
      [SETTING.SEND_BIRTHDAY_CONGRATULATIONS]: apiResponse.birthdayCampaignEnabled,
      [SETTING.SEND_PATCH_TEST_INVITES]: deprecatedApiResponse.sendPatchTestInvites,
      sendEmailReminders: deprecatedApiResponse.sendEmailReminders,
      emailReminderHours: deprecatedApiResponse.emailReminderHours,
      sendSmsReminders: deprecatedApiResponse.sendSmsReminders,
      smsReminderHours: deprecatedApiResponse.smsReminderHours,
      smsFromNumber: deprecatedApiResponse.smsFromNumber,
    };

    yield put(automatedMessagingSettingsSuccess(settings));
  } catch (responseError) {
    yield put(automatedMessagingSettingsFailure());
  }
}

export function* setAutomatedMessagingSettingRequested({
  payload,
}: ReturnType<typeof setAutomatedMessagingSettingRequest>) {
  try {
    // special case for appointment followups (thank you)
    if (typeof payload[SETTING.SEND_APPOINTMENT_FOLLOWUP] !== 'undefined') {
      yield call(setAppointmentFollowupSetting, payload as SendAppointmentFollowupSetting);
      return;
    }

    if (typeof payload[SETTING.SEND_BIRTHDAY_CONGRATULATIONS] !== 'undefined') {
      yield call(setBirthdayCongratulationsSetting, payload as BirthdayCongratulationsSetting);
      return;
    }

    if (typeof payload[SETTING.SEND_PATCH_TEST_INVITES] !== 'undefined') {
      yield call(setSendPatchTestInvites, payload as SendPatchTestInvitesSetting);
      return;
    }

    const mappedKeys = {};
    Object.entries(payload).forEach(([key, value]) => {
      if (key.includes(';')) {
        key.split(';').forEach((singleKey) => {
          mappedKeys[singleKey] = value;
        });
      } else {
        mappedKeys[key] = value;
      }
    });

    yield call(api.put, apiUrl('VENUE'), mappedKeys);
    yield put(setAutomatedMessagingSettingSuccess({ ...payload, ...mappedKeys }));
  } catch (responseError) {
    yield put(automatedMessagingSettingsFailure());
  }
}

function* setSendPatchTestInvites(payload: SendPatchTestInvitesSetting) {
  try {
    let endpoint = '';
    if (payload[SETTING.SEND_PATCH_TEST_INVITES]) {
      endpoint = apiUrl('PATCH_TEST_INVITES_ENABLE');
    } else {
      endpoint = apiUrl('PATCH_TEST_INVITES_DISABLE');
    }
    yield call(api.post, endpoint);
    yield put(setAutomatedMessagingSettingSuccess(payload));
  } catch (responseError) {
    yield put(automatedMessagingSettingsFailure());
  }
}

function* setAppointmentFollowupSetting(payload: SendAppointmentFollowupSetting) {
  try {
    let endpoint = '';
    if (payload.sendAppointmentFollowup) {
      endpoint = apiUrl('APPOINTMENT_FOLLOWUP_ENABLE');
    } else {
      endpoint = apiUrl('APPOINTMENT_FOLLOWUP_DISABLE');
    }
    yield call(api.post, endpoint);
    yield put(setAutomatedMessagingSettingSuccess(payload));
  } catch (responseError) {
    yield put(automatedMessagingSettingsFailure());
  }
}

function* setBirthdayCongratulationsSetting(payload: BirthdayCongratulationsSetting) {
  try {
    const endpoint = payload.sendBirthdayCongratulations
      ? apiUrl('SUPPLIER_MARKETING_CAMPAIGNS_ENABLE_BIRTHDAY_CONGRATULATIONS')
      : apiUrl('SUPPLIER_MARKETING_CAMPAIGNS_DISABLE_BIRTHDAY_CONGRATULATIONS');
    yield call(api.post, endpoint, {});
    yield put(setAutomatedMessagingSettingSuccess(payload));
  } catch (responseError) {
    yield put(automatedMessagingSettingsFailure());
  }
}

export function* setReminderSettingsRequested({
  payload,
}: ReturnType<typeof setReminderSettingsRequest>) {
  try {
    yield call(api.put, apiUrl('VENUE'), payload);
    const successPayload = {
      ...payload,
      [SETTING.SEND_HELPFUL_REMINDERS]: payload.sendEmailReminders || payload.sendSmsReminders,
    };
    yield put(setReminderSettingsSuccess(successPayload));
  } catch (responseError) {
    yield put(automatedMessagingSettingsFailure());
  }
}
