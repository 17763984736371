import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import { Tooltip } from 'components/common/Tooltip';
import { Button } from 'components/common/Button';
import { Plus } from 'components/common/Icon';
import {
  getPricingType,
  addCustomSku,
  splitTwoDimensionalSkus,
  remove2dSkusFromList,
  add2dSkus,
  removeSkuFromList,
  usesEmployeeCategories,
} from 'components/menu/OfferTreatmentPricingAndEmployees/utils';
import {
  PRICING_TYPE_BY_EMPLOYEE_CAT,
  PRICING_TYPE_CUSTOM,
  PRICING_TYPE_2D,
} from 'components/menu/OfferTreatmentPricingAndEmployees/constants';
import { savePricingType } from './pricing-type';
import { convertSkusForProps } from './sku-conversion';
import { CleanUpTime } from './CleanupTime';
import TimeAndPricing from './TimeAndPricing/container';
import TwoDimensionalPricing from './TwoDimensionalPricing';
import SkuList from './SkuList/container';
import { DELETE_BUTTON_STATE } from './SkuList';
import { ServiceEditLabel } from '../../ServicesTab/tracking';
import style from './style.scss';

const LANG = Wahanda.lang.menu.offer.sku;

interface IOfferPricingProps {
  skus: any[];
  readonly: boolean;
  selectedEmployees: {
    id: number;
    name: string;
    employeeCategoryId?: number;
  }[];
  employeeCategoriesCollection: any[];
  escapeWithSkuPricing: boolean;
  useEmployeeCategoryPricing?: boolean;
  cleanupTime: number;
  processingTimeMins?: number;
  useProcessingTime: boolean;
  useDefaultValues?: boolean;
  allowProcessing: boolean;
  offerName: string;
  enclosedByPackage: boolean;
  onPossiblePricingTypeChange: (...args: any[]) => any;
  actions?: any;
  trackServiceEditAction?: any;
  length?: any;
}
type OfferPricingState = {
  skus?: any;
  cleanupTime?: any;
  processingTimeMins?: any;
};

export class OfferPricing extends React.Component<IOfferPricingProps, OfferPricingState> {
  skuRefs: any;

  wasChanged: boolean;

  constructor(props) {
    super(props);
    const { cleanupTime, processingTimeMins, skus } = this.props;
    /*
     *  We save the SKUs into the state, as we operate on the state
     *  when we add something, etc
     */
    this.state = { skus, cleanupTime, processingTimeMins };
    /*
     *  We mark as changed if the model is new (e.g. no skus)
     */
    this.wasChanged = !this.props.skus || this.props.skus.length === 0;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.wasChanged =
      this.wasChanged ||
      (usesEmployeeCategories(this.props.skus) &&
        nextProps.selectedEmployees.length !== this.props.selectedEmployees.length);
    const currentSkus = this.state.skus;
    const nextSkus = convertSkusForProps({
      currentSkus: this.getValues().skus,
      props: this.props,
      nextProps,
      wasChanged: this.wasChanged,
    });
    if (nextProps.allowProcessing !== this.props.allowProcessing) {
      this.setState({ processingTimeMins: null });
    }
    if (nextProps.processingTimeMins !== this.state.processingTimeMins) {
      this.setState({ processingTimeMins: nextProps.processingTimeMins });
    }
    if (currentSkus !== nextSkus) {
      this.setState({ skus: nextSkus });
      // On pricing type change, save the pricing type into LS
      savePricingType(getPricingType(nextSkus));
    }
  }

  onTimeSlotUpdate = ({ processingTimeMins, cleanupTime }) => {
    this.wasChanged = true;
    this.setState({ processingTimeMins, cleanupTime });
  };

  onCleanupTimeUpdate = (cleanupTime) => {
    this.setState({ cleanupTime });
    this.props.actions.trackServiceEditAction(ServiceEditLabel.CleanUpTime);
  };

  getValues() {
    const { processingTimeMins, cleanupTime } = this.state;
    const skus = this.skuRefs.getValues();
    return {
      skus,
      processingTimeMins,
      cleanupTime,
    };
  }

  get skuContent() {
    // Save the sku REFs for getting values when saving
    this.skuRefs = null;
    const { skus } = this.state;
    const { readonly, allowProcessing, enclosedByPackage, useProcessingTime } = this.props;
    const pricingType = getPricingType(skus);
    const canAddSkus = !readonly && !enclosedByPackage;
    // @ts-expect-error ts-migrate(2345) FIXME: Type 'null' is not assignable to type 'string'.
    const addSkuFn = [PRICING_TYPE_BY_EMPLOYEE_CAT, PRICING_TYPE_2D].includes(pricingType)
      ? this.add2dSku
      : this.addSku;
    this.props.onPossiblePricingTypeChange(pricingType);
    const isEmployeeCatPricing = pricingType === PRICING_TYPE_BY_EMPLOYEE_CAT;
    const canDeleteSkus =
      !readonly && !enclosedByPackage && !isEmployeeCatPricing && skus.length > 1;
    const skusComponent = (() => {
      if (pricingType === PRICING_TYPE_2D) {
        return (
          <TwoDimensionalPricing
            {...this.props}
            useProcessingTime={allowProcessing && useProcessingTime}
            readonly={this.props.readonly}
            skus={splitTwoDimensionalSkus(skus)}
            onDelete={this.remove2dSkus}
            canDelete={canDeleteSkus}
            ref={(skuList) => {
              this.skuRefs = skuList;
            }}
            className={classnames(style.pricingRoot, style.spaceBefore)}
            onSkuNameChange={this.onSkuNameChange}
          />
        );
      }
      const nameEditable = pricingType === PRICING_TYPE_CUSTOM;
      const deleteButtonSate = () => {
        if (canDeleteSkus) {
          return DELETE_BUTTON_STATE.DEFAULT;
        }
        if (isEmployeeCatPricing || skus.length === 1 || readonly) {
          return DELETE_BUTTON_STATE.HIDDEN;
        }
        return DELETE_BUTTON_STATE.DISABLED;
      };
      const deleteButton = deleteButtonSate();
      return (
        // @ts-expect-error ts-migrate(2741) FIXME: Property 'readonlyLight' is missing in type '{ sku... Remove this comment to see the full error message
        <SkuList
          {...this.props}
          skus={skus}
          useProcessingTime={allowProcessing && useProcessingTime}
          onDelete={this.removeSku}
          ref={(skuList) => {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'getWrappedInstance' does not exist on ty... Remove this comment to see the full error message
            this.skuRefs = skuList ? skuList.getWrappedInstance() : null;
          }}
          className={classnames(style.pricingRoot, style.spaceBefore)}
          nameEditable={nameEditable}
          deleteButton={deleteButton}
        />
      );
    })();
    return (
      <div>
        {skusComponent}
        {this.addSkusButton(canAddSkus, addSkuFn, readonly)}
      </div>
    );
  }

  get additionalTime() {
    return (
      <CleanUpTime
        cleanupTime={this.props.cleanupTime}
        onDurationChange={this.onCleanupTimeUpdate}
        escapeWithSkuPricing={this.props.escapeWithSkuPricing}
      />
    );
  }

  addSkusButton = (canAddSkus, addSkuFn, isReadOnly) => {
    if (isReadOnly) {
      return null;
    }
    const button = (
      <div className={style.addButton}>
        <Button
          dataTest="add-sku-button"
          disabled={!canAddSkus}
          label={LANG.addPricingOptions}
          onClick={addSkuFn}
          colour="plain"
          icon={<Plus className={style.addButtonIcon} />}
          size="small"
        />
      </div>
    );
    if (canAddSkus) {
      return button;
    }
    return (
      <div className={style.tooltip}>
        <Tooltip tooltip={LANG.canNotEdit} placement="top">
          {button}
        </Tooltip>
      </div>
    );
  };

  onSkuNameChange = () => {
    this.props.actions.trackServiceEditAction(ServiceEditLabel.SkuName);
  };

  removeSku = (sku) => {
    this.wasChanged = true;
    this.setState(
      (prevState) => ({
        skus: removeSkuFromList(prevState.skus, sku),
      }),
      () => {
        this.props.actions.trackServiceEditAction(ServiceEditLabel.RemovePricingOption);
      },
    );
  };

  remove2dSkus = (skusToRemove) => {
    this.wasChanged = true;
    this.setState(
      (prevState) => ({
        skus: remove2dSkusFromList(prevState.skus, skusToRemove),
      }),
      () => {
        this.props.actions.trackServiceEditAction(ServiceEditLabel.RemovePricingOption);
      },
    );
  };

  addSku = () => {
    this.wasChanged = true;
    this.setState(
      () => ({ skus: addCustomSku(this.getValues().skus) }),
      () => {
        this.props.actions.trackServiceEditAction(ServiceEditLabel.AddPricingOption);
      },
    );
  };

  add2dSku = () => {
    this.wasChanged = true;
    this.setState(
      () => ({
        skus: add2dSkus(this.getValues().skus, {
          selectedEmployees: this.props.selectedEmployees,
          employeeCategoriesCollection: this.props.employeeCategoriesCollection,
        }),
      }),
      () => {
        this.props.actions.trackServiceEditAction(ServiceEditLabel.AddPricingOption);
      },
    );
  };

  render() {
    /*
     *  We listen for any change events so that we could track if we can return
     *  the original SKUs when switching staff pricing flag
     */
    const handleChange = this.wasChanged
      ? null
      : () => {
          this.wasChanged = true;
        };
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '((event: Fo... Remove this comment to see the full error message
      <div className={style.wrapper} onChange={handleChange} data-test="offer-pricing-wrapper">
        <div className={style.spaceBefore}>
          <TimeAndPricing />
        </div>
        {this.skuContent}
        {this.additionalTime}
      </div>
    );
  }
}
