/* eslint-disable camelcase */
/* eslint quote-props: 0 */
const CHANNEL_CODES = require('./channelCodes');
const LANGUAGE_LOCALES = require('./languageLocales');
const googleMapKeys = require('./googleMapKeys');

const config = {
  GOOGLE_MAP_KEY_FOR_CHANNEL: googleMapKeys,
  DOMAIN_TO_LOCALE_MAP: {
    // Original domains
    'wahanda.com': LANGUAGE_LOCALES.EN,
    'wahanda.ie': LANGUAGE_LOCALES.IE,
    'zensoon.com': LANGUAGE_LOCALES.FR,
    'wahanda.es': LANGUAGE_LOCALES.ES,
    'wahanda.it': LANGUAGE_LOCALES.IT,
    'salonmeister.de': LANGUAGE_LOCALES.DE,
    'salonmeister.at': LANGUAGE_LOCALES.AT,
    'salonmeister.ch': LANGUAGE_LOCALES.CH,
    // Mobile API domain
    'treatwell.net': LANGUAGE_LOCALES.EN,
    // New domains
    'treatwell.co.uk': LANGUAGE_LOCALES.EN,
    'treatwell.ie': LANGUAGE_LOCALES.IE,
    'treatwell.fr': LANGUAGE_LOCALES.FR,
    'treatwell.es': LANGUAGE_LOCALES.ES,
    'treatwell.gr': LANGUAGE_LOCALES.EL,
    'treatwell.pt': LANGUAGE_LOCALES.PT,
    'treatwell.it': LANGUAGE_LOCALES.IT,
    'treatwell.de': LANGUAGE_LOCALES.DE,
    'treatwell.ch': LANGUAGE_LOCALES.CH,
    'treatwell.at': LANGUAGE_LOCALES.AT,
    'treatwell.nl': LANGUAGE_LOCALES.NL,
    'treatwell.be': LANGUAGE_LOCALES.NL_BE,
    'treatwell.lt': LANGUAGE_LOCALES.LT,
    'treatwell.dk': LANGUAGE_LOCALES.DA,
    'treatwell.lv': LANGUAGE_LOCALES.LV,
  },
  DOMAIN_TO_LOCALE_MAP_INVERTED: {
    [LANGUAGE_LOCALES.EN]: 'treatwell.co.uk',
    [LANGUAGE_LOCALES.DE]: 'treatwell.de',
    [LANGUAGE_LOCALES.FR]: 'treatwell.fr',
    [LANGUAGE_LOCALES.NL]: 'treatwell.nl',
    [LANGUAGE_LOCALES.EN_NL]: 'treatwell.nl',
    [LANGUAGE_LOCALES.LT]: 'treatwell.lt',
    [LANGUAGE_LOCALES.IE]: 'treatwell.ie',
    [LANGUAGE_LOCALES.CH]: 'treatwell.ch',
    [LANGUAGE_LOCALES.AT]: 'treatwell.at',
    [LANGUAGE_LOCALES.FR_BE]: 'treatwell.be',
    [LANGUAGE_LOCALES.EN_BE]: 'treatwell.be',
    [LANGUAGE_LOCALES.NL_BE]: 'treatwell.be',
    [LANGUAGE_LOCALES.IT]: 'treatwell.it',
    [LANGUAGE_LOCALES.ES]: 'treatwell.es',
    [LANGUAGE_LOCALES.EL]: 'treatwell.gr',
    [LANGUAGE_LOCALES.PT]: 'treatwell.pt',
    [LANGUAGE_LOCALES.DA]: 'treatwell.de',
    [LANGUAGE_LOCALES.RU]: 'treatwell.co.uk',
    [LANGUAGE_LOCALES.VI]: 'treatwell.co.uk',
    [LANGUAGE_LOCALES.LV]: 'treatwell.co.uk',
  },
  CHANNEL_CODES,
  CHANNEL_CODE_TO_LOCALE_MAP: {
    [CHANNEL_CODES.GB]: LANGUAGE_LOCALES.EN,
    [CHANNEL_CODES.DE]: LANGUAGE_LOCALES.DE,
    [CHANNEL_CODES.ES]: LANGUAGE_LOCALES.ES,
    [CHANNEL_CODES.GR]: LANGUAGE_LOCALES.EL,
    [CHANNEL_CODES.PT]: LANGUAGE_LOCALES.PT,
    [CHANNEL_CODES.FR]: LANGUAGE_LOCALES.FR,
    [CHANNEL_CODES.BE]: LANGUAGE_LOCALES.NL_BE,
    [CHANNEL_CODES.IE]: LANGUAGE_LOCALES.IE,
    [CHANNEL_CODES.IT]: LANGUAGE_LOCALES.IT,
    [CHANNEL_CODES.AT]: LANGUAGE_LOCALES.AT,
    [CHANNEL_CODES.CH]: LANGUAGE_LOCALES.CH,
    [CHANNEL_CODES.NL]: LANGUAGE_LOCALES.NL,
    [CHANNEL_CODES.LT]: LANGUAGE_LOCALES.LT,
    [CHANNEL_CODES.DK]: LANGUAGE_LOCALES.DA,
    [CHANNEL_CODES.LV]: LANGUAGE_LOCALES.LV,
  },
  LANGUAGE_LOCALES,
  LOCALE_TO_CHANNEL_CODE_MAP: {
    [LANGUAGE_LOCALES.EN]: CHANNEL_CODES.GB,
    [LANGUAGE_LOCALES.DE]: CHANNEL_CODES.DE,
    [LANGUAGE_LOCALES.DA]: CHANNEL_CODES.DK,
    [LANGUAGE_LOCALES.ES]: CHANNEL_CODES.ES,
    [LANGUAGE_LOCALES.EL]: CHANNEL_CODES.GR,
    [LANGUAGE_LOCALES.FR]: CHANNEL_CODES.FR,
    [LANGUAGE_LOCALES.FR_BE]: CHANNEL_CODES.BE,
    [LANGUAGE_LOCALES.EN_BE]: CHANNEL_CODES.BE,
    [LANGUAGE_LOCALES.NL_BE]: CHANNEL_CODES.BE,
    [LANGUAGE_LOCALES.IE]: CHANNEL_CODES.IE,
    [LANGUAGE_LOCALES.IT]: CHANNEL_CODES.IT,
    [LANGUAGE_LOCALES.AT]: CHANNEL_CODES.AT,
    [LANGUAGE_LOCALES.CH]: CHANNEL_CODES.CH,
    [LANGUAGE_LOCALES.NL]: CHANNEL_CODES.NL,
    [LANGUAGE_LOCALES.EN_NL]: CHANNEL_CODES.NL,
    [LANGUAGE_LOCALES.LT]: CHANNEL_CODES.LT,
    [LANGUAGE_LOCALES.LV]: CHANNEL_CODES.LV,
    [LANGUAGE_LOCALES.PT]: CHANNEL_CODES.PT,
    [LANGUAGE_LOCALES.RU]: CHANNEL_CODES.GB,
    [LANGUAGE_LOCALES.VI]: CHANNEL_CODES.GB,
  },
  LOCALE_TO_TRANSLATION_MAP: {
    [LANGUAGE_LOCALES.EN]: 'English',
    [LANGUAGE_LOCALES.DA]: 'Dansk',
    [LANGUAGE_LOCALES.DE]: 'Deutsch',
    [LANGUAGE_LOCALES.ES]: 'Español',
    [LANGUAGE_LOCALES.EL]: 'Greek',
    [LANGUAGE_LOCALES.FR]: 'Français',
    [LANGUAGE_LOCALES.FR_BE]: 'Français (Belgique)',
    [LANGUAGE_LOCALES.EN_BE]: 'English (Belgium)',
    [LANGUAGE_LOCALES.NL_BE]: 'Nederlands (België)',
    [LANGUAGE_LOCALES.IE]: 'English (Ireland)',
    [LANGUAGE_LOCALES.IT]: 'Italiano',
    [LANGUAGE_LOCALES.AT]: 'Deutsch (Österreich)',
    [LANGUAGE_LOCALES.CH]: 'Deutsch (Schweiz)',
    [LANGUAGE_LOCALES.NL]: 'Nederlands',
    [LANGUAGE_LOCALES.EN_NL]: 'English (Netherlands)',
    [LANGUAGE_LOCALES.LT]: 'Lietuvių',
    [LANGUAGE_LOCALES.LV]: 'Latviešu',
    [LANGUAGE_LOCALES.PT]: 'Português',
    [LANGUAGE_LOCALES.RU]: 'Русский',
    [LANGUAGE_LOCALES.VI]: 'Tiếng Việt',
  },
  LOCALE_TO_COUNTRY_NAME: {
    [LANGUAGE_LOCALES.DA]: 'Danmark',
    [LANGUAGE_LOCALES.DE]: 'Deutschland',
    [LANGUAGE_LOCALES.ES]: 'España',
    [LANGUAGE_LOCALES.EL]: 'Greece',
    [LANGUAGE_LOCALES.FR]: 'France',
    [LANGUAGE_LOCALES.IT]: 'Italia',
    [LANGUAGE_LOCALES.LT]: 'Lietuva',
    [LANGUAGE_LOCALES.LV]: 'Latvija',
    [LANGUAGE_LOCALES.NL]: 'Nederland',
    [LANGUAGE_LOCALES.EN]: 'UK',
    [LANGUAGE_LOCALES.FR_BE]: 'Belgique',
    [LANGUAGE_LOCALES.EN_BE]: 'Belgium',
    [LANGUAGE_LOCALES.NL_BE]: 'België',
    [LANGUAGE_LOCALES.IE]: 'Ireland',
    [LANGUAGE_LOCALES.AT]: 'Österreich',
    [LANGUAGE_LOCALES.CH]: 'Schweiz',
    [LANGUAGE_LOCALES.EN_NL]: 'Netherlands',
    [LANGUAGE_LOCALES.PT]: 'Portugal',
    [LANGUAGE_LOCALES.RU]: 'Россия',
    [LANGUAGE_LOCALES.VI]: 'Việt Nam',
  },
  LOCALES_TO_IDENTITY_VERIFICATION_LEARN_MORE_LINK: {
    [LANGUAGE_LOCALES.EN]: 'https://partners.treatwell.com/hc/en-gb/articles/10985183939730',
    [LANGUAGE_LOCALES.EL]: 'https://partners.treatwell.com/hc/en-gb/articles/10985183939730',
    [LANGUAGE_LOCALES.IE]: 'https://partners.treatwell.com/hc/en-gb/articles/10985183939730',
    [LANGUAGE_LOCALES.DE]:
      'https://partners.treatwell.com/hc/de/articles/10985183939730-Accountverifizierung-%C3%BCber-Stripe',
    [LANGUAGE_LOCALES.AT]:
      'https://partners.treatwell.com/hc/de-at/articles/10985183939730-Accountverifizierung-%C3%BCber-Stripe',
    [LANGUAGE_LOCALES.ES]:
      'https://partners.treatwell.com/hc/es/articles/10985183939730-Verificaci%C3%B3n-de-cuenta-en-Stripe',
    [LANGUAGE_LOCALES.FR]:
      'https://partners.treatwell.com/hc/fr-fr/articles/10985183939730-V%C3%A9rification-du-compte-sur-StripeUntitled',
    [LANGUAGE_LOCALES.FR_BE]:
      'https://partners.treatwell.com/hc/fr-be/articles/10985183939730-V%C3%A9rification-du-compte-sur-Stripe',
    [LANGUAGE_LOCALES.NL_BE]:
      'https://partners.treatwell.com/hc/nl-be/articles/10985183939730-Accountverificatie-op-Stripe',
    [LANGUAGE_LOCALES.IT]:
      'https://partners.treatwell.com/hc/it/articles/10985183939730-Verifica-dell-account-su-Stripe',
    [LANGUAGE_LOCALES.LT]:
      'https://partners.treatwell.com/hc/lt/articles/10985183939730-Paskyros-patvirtinimas-per-Stripe',
    [LANGUAGE_LOCALES.NL]:
      'https://partners.treatwell.com/hc/nl/articles/10985183939730-Accountverificatie-op-Stripe',
    [LANGUAGE_LOCALES.CH]:
      'https://partners.treatwell.com/hc/de-ch/articles/10985183939730-Accountverifizierung-%C3%BCber-Stripe',
  },
};

config.LOCALE_TO_COUNTRY_NAME_INVERTED = {};
Object.keys(config.LOCALE_TO_COUNTRY_NAME).forEach((key) => {
  config.LOCALE_TO_COUNTRY_NAME_INVERTED[config.LOCALE_TO_COUNTRY_NAME[key]] = key;
});

config.LOCALES = [];
Object.keys(config.LOCALE_TO_CHANNEL_CODE_MAP).forEach((key) => {
  config.LOCALES.push(key);
});

config.MASQUERADED_LOCALES = [];

module.exports = config;
