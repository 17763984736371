// Taken from react router v6
export enum NavigationType {
  Pop = 'POP',
  Push = 'PUSH',
  Replace = 'REPLACE',
}

export type UpdateLocationParams = {
  pathname: string;
  navigationType: NavigationType;
};

export const updateLocation = (prefix: string, data: UpdateLocationParams) => {
  if (data.navigationType === NavigationType.Push) {
    window.history.pushState(null, '', `${prefix}${data.pathname}`);
  } else if (data.navigationType === NavigationType.Replace) {
    window.history.replaceState(null, '', `${prefix}${data.pathname}`);
  }
};

export const getStorePath = (): string | null => {
  const path = window.location.pathname;
  // Match an optional language code segment (like /en or /en_EN) before /store
  const match = path.match(/(?:\/[a-zA-Z]{2,3}(?:[_-][a-zA-Z]{2})?)?\/store\/?(.*)/);
  if (match) {
    return match[1];
  }
  return null;
};

export const getStorePrefix = (): string | null => {
  const path = window.location.pathname;
  // Match the beginning of the path up to /store
  const match = path.match(/^((?:\/[a-zA-Z]{2,3}(?:[_-][a-zA-Z]{2})?)?\/store)/);
  if (match) {
    return match[0]; // Return the part before /store
  }
  return null;
};

export const isOnStore = (): boolean => getStorePrefix() !== null;
