import React from 'react';
import Formsy from 'formsy-react';
import { UserAutocomplete } from 'components/common/UserAutocomplete';
import Wahanda from 'common/wahanda';

import { InputError } from 'components/common/__BaseCommon';
import { Edit } from 'components/common/Icon';
import { Button } from 'components/common/Button';
import { Tooltip, TooltipPlacement } from 'components/common/Tooltip';
import { customerNameValidator, emailValidator, phoneValidator } from 'common/validators';

import style from './InlineClientCreation.scss';

const LANG = Wahanda.lang.inlineClientEditing;
const AUTOCOMPLETE_WIDTH = 189;

export interface Props {
  dataSource: () => void;
  onEditTrigger: (param) => void;
  // redux
  actions: {
    clientSelected: () => void;
    setClientProperties: (param) => void;
    resetCustomerAction: () => void;
  };
  name?: string | '';
  phone?: string | '';
  emailAddress?: string | '';
  toggleIgnoreKeyboardEvents: (param: boolean) => void;
  serverError?: string;
}

interface State {
  wasSubmitted: boolean;
  isValid: boolean | null;
  showServerError: boolean;
}

export class InlineClientCreation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      wasSubmitted: false,
      isValid: null,
      showServerError: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.serverError && this.props.serverError) {
      this.onInvalid();
      this.setState({ wasSubmitted: false, showServerError: true });
    }
  }

  private onValid = () => {
    if (!this.state.isValid) {
      this.setState({
        isValid: true,
      });
    }
  };

  private onInvalid = () => {
    if (this.state.isValid) {
      this.setState({
        isValid: false,
      });
    }
  };

  private onSubmit = () => {
    /**
     * We set submit to true and force the form to re-render
     * to show messages.
     */
    this.setState({
      wasSubmitted: true,
    });
  };

  private setName = ({ target: { value } }) => {
    this.updateClient('name', value);
  };

  private setPhone = ({ target: { value } }) => {
    this.updateClient('phone', value);
  };

  private setEmail = ({ target: { value } }) => {
    this.updateClient('emailAddress', value);
  };

  private updateClient = (key, value) => {
    const {
      actions: { setClientProperties, resetCustomerAction },
      phone,
      emailAddress,
      name,
    } = this.props;

    setClientProperties(
      Object.assign(
        {},
        {
          name,
          phone,
          emailAddress,
        },
        {
          [key]: value,
        },
      ),
    );

    this.setState({ showServerError: false });
    resetCustomerAction();
  };

  private openClientDialog = () => {
    const { emailAddress, name, phone } = this.props;
    this.props.onEditTrigger({ customerData: { emailAddress, name, phone } });
  };

  private getServerErrorMessage() {
    const { serverError } = this.props;
    switch (serverError) {
      case 'invalid-venue-customer-email':
        return Wahanda.lang.validate.defaults.email;
      default:
        return Wahanda.lang.shared.errors.labels.savingFailed;
    }
  }

  // private formRef: React.RefObject<HTMLFormElement>;
  //  TODO: check TypeScript and form .submit()
  public formRef: any;

  public submitForm = () => {
    this.formRef.submit();
  };

  private disableSavingOnEnter = () => {
    this.props.toggleIgnoreKeyboardEvents(true);
  };

  private enableSavingOnEnter = () => {
    // "enter" will submit the form. Enable the event handling back
    // after a bit so that we would not have a strange UI experience.
    window.setTimeout(() => this.props.toggleIgnoreKeyboardEvents(false), 100);
  };

  private areFieldsEmpty = (fields) => fields.every((value) => !!value === false);

  public render() {
    const {
      dataSource,
      actions: { clientSelected },
      phone,
      emailAddress,
      name,
    } = this.props;

    const { showServerError } = this.state;
    const fieldsLang = LANG.fields;
    const sharedProps = {
      dataSource,
      onOpen: this.disableSavingOnEnter,
      onClose: this.enableSavingOnEnter,
      onSelect: clientSelected,
    };

    return (
      <Formsy
        ref={(form) => {
          this.formRef = form;
        }}
        className={style.inlineClient}
        onSubmit={this.onSubmit}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
      >
        <div className={style.title}>{LANG.title}</div>
        <div className={style.inputs}>
          <UserAutocomplete
            {...sharedProps}
            value={name}
            wrapperClasses={style.name}
            placeholder={fieldsLang.name}
            onChange={this.setName}
            name="name"
            validations={{
              [customerNameValidator.KEY]: customerNameValidator.VALIDATOR,
            }}
            disableAutoComplete={!this.areFieldsEmpty([emailAddress, phone])}
            dataTest="udv-client-name-input"
          />
          <UserAutocomplete
            {...sharedProps}
            value={phone}
            name="phone"
            validations={{
              [phoneValidator.KEY]: phoneValidator.VALIDATOR,
            }}
            placeholder={fieldsLang.phone}
            onChange={this.setPhone}
            wrapperClasses={style.phone}
            disableAutoComplete={!this.areFieldsEmpty([name, emailAddress])}
            dropdownWidth={AUTOCOMPLETE_WIDTH}
            dataTest="udv-client-phone-input"
          />
          <UserAutocomplete
            {...sharedProps}
            value={emailAddress}
            name="emailAddress"
            validations={{
              [emailValidator.KEY]: emailValidator.VALIDATOR,
            }}
            placeholder={fieldsLang.email}
            onChange={this.setEmail}
            wrapperClasses={style.email}
            disableAutoComplete={!this.areFieldsEmpty([name, phone])}
            dropdownWidth={AUTOCOMPLETE_WIDTH}
            dataTest="udv-client-email-input"
          />
          <div className={style.editAction}>
            <Tooltip tooltip={LANG.messages.hoverEditClient} placement={TooltipPlacement.top}>
              <Button
                onClick={this.openClientDialog}
                colour="plain"
                icon={<Edit size="small" />}
                size="small"
              />
            </Tooltip>
          </div>
        </div>
        {showServerError && <InputError message={this.getServerErrorMessage()} />}
      </Formsy>
    );
  }
}
