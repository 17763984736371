module.exports.colors = {
  colorCoralDark: '#cb3a29',
  colorCoral: '#ff5c39',
  colorCoralPastel: '#ffeeea',
  colorPinkDark: '#c24353',
  colorPink: '#ff808b',
  colorPinkPastel: '#fff0f1',
  colorYellowDark: '#9a6914',
  colorYellow: '#ffb500',
  colorYellowPastel: '#fef1d1',
  colorGoldDark: '#726d40',
  colorGold: '#d6cf8d',
  colorGoldPastel: '#f7f5e6',
  colorGreenDark: '#37824b',
  colorGreen: '#3ba150',
  colorGreenPastel: '#ecffea',
  colorNavyDark: '#0d1e44',
  colorNavy: '#001e62',
  colorNavyPastel: '#e7efff',
  colorTealDark: '#2f7b84',
  colorTeal: '#00a5ad',
  colorTealPastel: '#e6f9fa',
  colorWhite: '#fff',
  colorGrey98: '#FAFAFA',
  colorGrey95: '#F2F2F2',
  colorGrey90: '#E6E6E6',
  colorGrey70: '#B3B3B3',
  colorGrey50: '#808080',
  colorGrey40: '#666',
  colorGrey30: '#4d4d4d',
  colorGrey20: '#333',
  colorGrey10: '#1a1a1a',
  colorBlack: '#000',
  colorFacebook: '#1877F2',
  colorTwitter: '#00A7F1',
};
