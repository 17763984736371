import App from 'common/backbone-app';
import * as Sentry from '@sentry/browser';
import { useSalesforceBot } from '@treatwell/ui';
import { getSalesForceLocale } from 'common/salesforce-bot/getSalesForceLocale';
import { getSalesForceScriptConfig } from 'common/salesforce-bot/getSalesForceScriptConfig';
import { useEffect, useMemo } from 'react';
import { getTrackingByEventName } from 'src/tracking-container/TrackingEvents';
import { ChatSourceType } from 'src/tracking-container/events/chat';
import { sanitizeValues } from 'common/salesforce-bot/sanitizeValues';
import { getTokens } from 'common/salesforce-bot/getTokens';
import { getExternalUserFullName } from 'common/salesforce-bot/getExternalUserFullName';
import { FAKE_MASQUERADING_EMAIL } from 'common/salesforce-bot/constants';

const trackChatOpened = getTrackingByEventName('chat_opened');

let isLoadingSalesForceBot = false;

const SalesforceBot = () => {
  const {
    bootstrapJsSrc,
    init: { orgId, eswConfigDevName, siteUrl },
    scrt2URL,
  } = useMemo(() => getSalesForceScriptConfig(), []);

  const locale = useMemo(() => getSalesForceLocale(), []);

  const initialValues = useMemo(
    () =>
      sanitizeValues({
        externalUserFullName: getExternalUserFullName(),
        externalUserId: App.config.getAccountId(),
        externalVenueId: App.config.get('venue').id,
        externalLanguage: getSalesForceLocale(),
        externalSource: 'Web', // Web, iOS, Android
        externalUserEmail: App.isMasquerading()
          ? FAKE_MASQUERADING_EMAIL
          : App.config.get('account').emailAddress,
        externalCountry: App.config.get('venue').countryCode,
      }),
    [],
  );

  const { loadBot } = useSalesforceBot({
    init: { orgId, eswConfigDevName, siteUrl, scrt2URL },
    bootstrapJsSrc,
    initialPrechat: { hidden: initialValues.valids },
    locale,
    getTokens,
  });

  const openChat = ({ source }: { source: ChatSourceType }) => {
    trackChatOpened({ service: 'Salesforce', source });

    Sentry.captureMessage(
      `openChat - AccId: ${App.config.getAccountId()}, VenueId: ${App.config.get('venue').id}`,
    );

    if (window?.embeddedservice_bootstrap?.utilAPI?.launchChat) {
      Sentry.captureMessage(`openChat - SalesForce chat READY and LAUNCHING NOW`);
      window.embeddedservice_bootstrap.utilAPI.launchChat();
    } else if (isLoadingSalesForceBot) {
      Sentry.captureMessage(`openChat - SalesForce chat ALREADY LOADING`);
      Wahanda.ToastNotifications.sendMessage('The chat is loading, please wait!', {
        variant: 'danger',
        timeout: 2500,
      });
    } else {
      Sentry.captureMessage(`openChat - SalesForce chat initialise: ATTEMPT`);
      isLoadingSalesForceBot = true;
      loadBot()
        .then(() => {
          Sentry.captureMessage(`openChat - SalesForce chat initialise: SUCCESS`);
        })
        .catch((error) => {
          Sentry.captureMessage(`openChat - SalesForce chat initialise: ERROR ${error.toString()}`);
          Sentry?.captureException(error);
          Wahanda.ToastNotifications.sendMessage('Failed to open chat, please try again later.', {
            variant: 'danger',
            timeout: 2500,
          });
        })
        .finally(() => {
          isLoadingSalesForceBot = false;
        });
    }
  };

  useEffect(() => {
    App.on(Wahanda.Event.SALESFORCE_BOT_OPEN, openChat);
    () => {
      App.off(Wahanda.Event.SALESFORCE_BOT_OPEN);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default SalesforceBot;
