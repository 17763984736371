import { withErrorHandling } from 'helpers/withErrorHandling';

const LOCAL_STORAGE_KEY = 'calendar.invalid-sender-id-dialog.dismissed';
const MAX_DISMISSED_COUNT = 10;

export const isDismissed = withErrorHandling((): boolean => {
  const { forever, dismissedCount } = getDismissed();
  return forever || dismissedCount >= MAX_DISMISSED_COUNT;
});

export const dismiss = withErrorHandling(
  ({ forever = false }: { forever?: boolean } = {}): void => {
    const { dismissedCount } = getDismissed();
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({ forever, dismissedCount: dismissedCount + 1 }),
    );
  },
);

const getJSONFromLocalStorage = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) ?? '{}');
  } catch (e) {
    return {};
  }
};

const getDismissed = () => {
  const { forever = false, dismissedCount = 0 } = getJSONFromLocalStorage(LOCAL_STORAGE_KEY);
  return { forever, dismissedCount };
};
