import { useEffect, useState } from 'react';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { validatePhoneNumber } from 'components/marketing/AutomatedMessaging/AdvancedReminderSettings/validatePhoneNumber';
import { ReminderSettings } from 'components/marketing/AutomatedMessaging/types';

interface Props {
  enabled: boolean;
}

export const usePhoneNumber = ({ enabled = false }: Props) => {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    api.get<ReminderSettings>(apiUrl('VENUE')).then(({ sendSmsReminders, smsFromNumber }) => {
      if (sendSmsReminders && !validatePhoneNumber(smsFromNumber)) {
        setIsValid(false);
      }
    });
  }, [enabled]);

  return { isValid };
};
