import { AutomatedMessagingCategory } from 'components/marketing/AutomatedMessaging/types';

interface Props {
  paths: string[];
  tab: AutomatedMessagingCategory;
  isAdvancedSettingsVisible: boolean;
}

export const redirectTo = ({ paths, tab, isAdvancedSettingsVisible }: Props) => {
  const pathname = Wahanda.Url.getFullUrl(...paths);
  const { protocol, hostname } = window.location;
  const url = new URL(pathname, `${protocol}//${hostname}`);

  url.searchParams.set('tab', tab);
  url.searchParams.set('isAdvancedSettingsVisible', isAdvancedSettingsVisible.toString());

  window.location = url.toString() as string & Location;
};
